import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Skeleton states</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Loading components</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Load more</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "purpose"
    }}>{`Purpose`}</h2>
    <p><em parentName="p">{`Loading`}</em>{` is applied when additional information takes an extended amount of time to process and appear on screen. Skeleton states and the loading component are two interactions that communicate to users that data is currently loading and the screen is not frozen.`}</p>
    <h2 {...{
      "id": "skeleton-states"
    }}>{`Skeleton states`}</h2>
    <p><em parentName="p">{`Skeleton states`}</em>{` are simplified versions of Carbon components used on an initial page load to indicate that the information on the page has not entirely loaded yet. They should only appear for 1–3 seconds, disappearing once the actual content loads in.`}</p>
    <p>{`A Carbon skeleton state is shown as a stripped down, bare bones version of a component, using a `}<inlineCode parentName="p">{`$field-01`}</inlineCode>{` rectangular block for text. Motion is used in a skeleton state to convey that the page is not stuck loading but rather that data is still being pulled in.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <img {...{
          "src": "/baef2e67c4a838b8e79a47b8c6fc1bed/data-table-skeleton-state.gif",
          "alt": "Example of a text skeleton state in use on a Data Table"
        }}></img>
        <Caption mdxType="Caption">Example of a text skeleton state in a data table.</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "usage"
    }}>{`Usage`}</h3>
    <p>{`Skeleton states are not meant to represent every component on a page, but rather to illustrate the overall architecture of the page while it’s loading. Typically, components that should have skeleton states are container-based components `}<em parentName="p">{`(tiles, structured list, etc.)`}</em>{`, data-based components `}<em parentName="p">{`(e.g., data tables)`}</em>{`, and data-based text. Action components `}<em parentName="p">{`(button, input fields, checkboxes, toggle, etc.)`}</em>{` do not need to have a skeleton state in most use cases, as they are not pulling in data and do not need additional time to load in.`}</p>
    <p>{`There are several components that should `}<strong parentName="p">{`never`}</strong>{` be represented by a skeleton state since the data for these items should already be loaded in by the time a user interacts with them. These include:`}</p>
    <ul>
      <li parentName="ul">{`Toast notification`}</li>
      <li parentName="ul">{`Overflow menu`}</li>
      <li parentName="ul">{`Dropdown items`}</li>
      <li parentName="ul">{`Modal (elements inside a modal may have a skeleton state, however the modal itself should not be skeletonized)`}</li>
      <li parentName="ul">{`Small and large loader (used for other loading cases but not skeleton states)`}</li>
    </ul>
    <h3 {...{
      "id": "progressive-loading"
    }}>{`Progressive loading`}</h3>
    <p>{`When utilizing skeleton states, the order in which components, data, and content load in needs to be planned and designed for using the `}<em parentName="p">{`progressive loading`}</em>{` technique. Progressive loading is when content loads in batches. The first batch to load should be the most simplified view of the page, followed by the secondary and tertiary batches, with each batch adding more page detail until the entire viewport has been loaded.`}</p>
    <br />
The primary batch should illustrate a page's structural layout (the skeleton state versions of the container-based components), data-based text (the skeleton state version of text) and non-data text. Secondary and tertiary batches can include images, content below the fold, interactive (action-based) components, and/or data-based text. Remember that not all items need a skeleton state, but instead can be expressed as negative or white space until they load in. _For example, a 600 x 600 px image could be shown as a 600 x 600 px area of white space until the secondary or tertiary batch when the full image loads in._
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "130.64876957494405%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAAsSAAALEgHS3X78AAAECklEQVRIx62VX2wURRzHF6JEzVmVHFASlERjogm+mGAaHwxUE/8R9VojUHvAWWo9oTHUGgSN9PBBwQdfbIxGJRceIMYYfZP4IM1RgYCGqPgn4YEEUuldub2Z3Z2d2Z3d8Ttzd9u7XBPQOMk3v9mZ2d/9fr/57O8s638cx46fsKxl96+9ceUDPUutO+5Kje7d37V9bO+nw2N7Dmd3jhezO15vF9a2QIOvjsG+URzatae4Y/fbh0bffOfL/O59fcYrHC65e+26FZjeNvXjqTsr1ZqiLFB+IBUTdflBpHxY6glFoEDGSkbKjJO/z6rfLnNFHXowCRXri7RlvkjjxRrjUSzCOMCLoQiiMAhhw/qci8jM9V4IVangvlQKe/s6HNYcP+35wpVRjAMyhmDDREGon+fXGA9iKNSROh4vNFO2yleJcVglLI0Drj4g4VVKqe3CiiLFEarLhHEIW0gidJgwDhFdGr9EiMPNQYZCegtKJHOcEw2HE0mEZ86db6TMlvOgUe1/OajrH+zgCJeXwt4nUBE6dD2Klfoc9iiUaUZ4w8o1PV3W8ntSA/ldXZlt+Q+f3Try8ZObX5p8YhNkbM5Ir2k9vjE3+dTA0ORz2/KTG4d3fjQwMvrFppHXNjQdLu7tG1yi54SSFVdtqhwft4hwmyzqKmh5XCoXai3K6T8r6vxMoCilH7RiU09ZxnUOheYwCjR/qCm4a0hzGEQJm1rEDQBPC4f6Uv64cNFcik1ZwiGYi7UMjw1rns08NHMfrnDT7Ry2YtPOoWzhULboGhzqCNs5FIS4CYeht6BEMsc53sHh1MmzxiEBh/jR/8QhUu7kEBd6K8p3BPvfQF9fjwDCV/jEv0NZNyccdq/pSS3uvveWvlw+tWFw+K1ntoy829ufLazvGzRal3nRqLe/+TxQePT5bAEsFjJbX57oz73yfiaXX990ePPqBx9ZZVk3dR+fPrXq0swVdWWupmzqqrmaY2Q7TFWppyq2oyo1F33RR8244kGgSr9cUucuUjVbLnemjBQ0NqRac2LUJETt670Q3IWRMgxSl6MPKsMlyhPONfohxvylHPthelEdnyCNj9y1a1RxLmKNRgQ5iKZse7rwijHfIEOoo5jP9Q0abD478m0LNh6vY8Nl2vV813E9wyEGjuITBGplW6cpzA8YTDwGDkXi8Kdf/+oE2/XDNGpDdBRwFkJIJpZcSElcIUNZH5p7xpgM8CeBuemHwG0iceg2HKJGy5Ayio02H7b3xfCa/ZC/Nx9hI2U0mNtt4n5fqZJpOC5hveTAzlVJabZSLRHqlWzilKjLzB6+FEhMeUz8jOehxOH4/gNtnadVj2Ft9vBDlpo5YGVXW1bxBct6GGt/H72v7dzT28fN+/8At22aAXEab4AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a dashboard using skeleton states, to load in with progressive loading over three screens.",
            "title": "Example of a dashboard using skeleton states, to load in with progressive loading over three screens.",
            "src": "/static/4dc41a8c0ba30c0f9baaf31fcffd12bf/fb070/Progressive-Loading.png",
            "srcSet": ["/static/4dc41a8c0ba30c0f9baaf31fcffd12bf/d6747/Progressive-Loading.png 288w", "/static/4dc41a8c0ba30c0f9baaf31fcffd12bf/09548/Progressive-Loading.png 576w", "/static/4dc41a8c0ba30c0f9baaf31fcffd12bf/fb070/Progressive-Loading.png 1152w", "/static/4dc41a8c0ba30c0f9baaf31fcffd12bf/fb104/Progressive-Loading.png 1728w", "/static/4dc41a8c0ba30c0f9baaf31fcffd12bf/53680/Progressive-Loading.png 1788w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">
  Example of a dashboard using skeleton states to demonstrate progressive
  loading
        </Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "code"
    }}>{`Code`}</h3>
    <p>{`The skeleton state for `}<em parentName="p">{`most`}</em>{` components can be displayed simply by adding a modifier class of `}<inlineCode parentName="p">{`bx--skeleton`}</inlineCode>{` to the component while data is loading and then removing the class after it has loaded in. You will have to create your own custom JavaScript to implement this based on your environment.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="bx--btn bx--btn--primary bx--skeleton"></button>
<button class="bx--btn bx--btn--primary">Button loaded</button>
`}</code></pre>
    <p>{`If you are using our `}<a parentName="p" {...{
        "href": "http://react.carbondesignsystem.com/"
      }}>{`React library`}</a>{` there are separate skeleton state components available to display while data is loading. View an `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/wq264y43k8"
      }}>{`example`}</a>{` of this for React.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const DataComponent = ({ loading, data }) => {
  if (loading) {
    return <CarbonComponent.skeleton />;
  }
  return <CarbonComponent field1={data.field1} />;
};
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Vanilla`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Accordion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Breadcrumb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkbox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Code Snippet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Data Table`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date Picker`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dropdown`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number Input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pagination`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Progress Indicator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Radio Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skeleton Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Slider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small Toggle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Structured List`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tabs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tag`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text Area`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text Input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "/b25543b619f05a381e0bb2bb046548c8/checkmark--glyph.svg",
              "alt": "available"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "loading-components"
    }}>{`Loading components`}</h2>
    <p>{`A `}<em parentName="p">{`loading`}</em>{` component is a rotating shape indicating the application is processing and informing users that the system has reacted to an action taken.`}</p>
    <p>{`Use cases include:`}</p>
    <ul>
      <li parentName="ul">{`When data processing takes more than a few seconds but no longer than one minute to load in`}</li>
      <li parentName="ul">{`User-inputted data is being saved or submitted`}</li>
    </ul>
    <h3 {...{
      "id": "loading-large"
    }}>{`Loading (large)`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "/components/loading/code"
      }}><em parentName="a">{`loading`}</em></a>{` component should be used when the entire page is processing; for example, when data is being submitted or saved by the user.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "114.20581655480984%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAAsSAAALEgHS3X78AAACUklEQVQ4y51UXWsTQRTdvyEIgg9KH0R9ENSAD0X6pPinVXyX4kNb0iatDSpNsh/zPTm9985sssluQnHhMJfZO2fO/ZriyZuPeP7+BK+PP+NodIJHL97h8cvRw/FqhGdvj3H04ROejr6gOL+cYjy5weRmhsvpb5yPp+C9s/Hkwbgg/4urazp3jQL0GevRaCurNg4+RPzvVzBJ1Rg0ygrK2kBpB037tUor+yizsflSk+1dFNYFLGuLZeNIpRe7JFsZj3lphNS4FEEiCnRhisb5CD7PYJtRtIZ1ERs79Oy0hh5J11cIeaOsFYWtUVaK0Gzd3nXeVTNEKoRVrYWwzUPXgcOslemRD0WzDtlYRz8c4mqFGBNCjISVhFkrTavv7Eeyo3TDYMgNKWAlspnDanMmhaAi2FxZ3rNbPpk026JQaaomhayNlQNbCqnCt2WJv3UNT/9CR+VehW3erPNrZbx6cijpktPFHb7+m0GRn5eDYY3UHZsOEIWN5KijLGwUcH7PFnP8uPsDF7JPOKCwzZtzYevmFqyoIpWG/jPR7v+eQmkb6cEmz/FKSARk81TU1KdcuJSa1KM+HGgbS2FpY2SVcDpIlTYCLp6xlsiSWr9vUlxW052G3dYYmphu27T2elKWFDI/AEMH9s34QcIqz3OXwOfR438qX8b9yqN4cPQ40W2efG6NKOOVRo9zm1oqNbqlPAKpffYQbpp6H+Th4GjofZxXBgtCo91wyEOvyHbuIj3AVh7gX1dzfD+d4dvPGca3pSjvET4E/FLLa228EDO07RflHn7816YSl4yaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a Large Loader in the context of a form page where data is being submitted.",
            "title": "Example of a Large Loader in the context of a form page where data is being submitted.",
            "src": "/static/5859dfbd8af59616cb253faff34a419d/fb070/Large-Loader.png",
            "srcSet": ["/static/5859dfbd8af59616cb253faff34a419d/d6747/Large-Loader.png 288w", "/static/5859dfbd8af59616cb253faff34a419d/09548/Large-Loader.png 576w", "/static/5859dfbd8af59616cb253faff34a419d/fb070/Large-Loader.png 1152w", "/static/5859dfbd8af59616cb253faff34a419d/fb104/Large-Loader.png 1728w", "/static/5859dfbd8af59616cb253faff34a419d/53680/Large-Loader.png 1788w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a large loader in context.</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "inline-loading"
    }}>{`Inline loading`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "/components/inline-loading/code"
      }}><em parentName="a">{`inline loading`}</em></a>{` component is used when a single component is processing and appears inline to the relative component. `}<em parentName="p">{`For example, when a user sends a user invite, an inline loading component indicates the system is processing the invite request until the request is complete.`}</em></p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <img {...{
          "src": "/a12da714d1543a1ebf216983682adb8d/inline-loading-animated.gif",
          "alt": "Example of small loader in the context of a Detail Page Header."
        }}></img>
        <Caption mdxType="Caption">Example of inline loader in context.</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "load-more"
    }}>{`Load more`}</h2>
    <p>{`A `}<em parentName="p">{`load more`}</em>{` action may be used to extend a list where there are a large number of options (25 or more) that the user can choose from. It can also be used in cases where the list of options is populated via a database. Using `}<em parentName="p">{`load more`}</em>{` allows the data to load in progressive batches.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.891304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB+UlEQVQoz4VT2W7aQBQ1wyOV+gV9r/pe9aELCm2UD0rVH6Lqd6C+FCwkwGYHgWts8IJXQA6G5PbcgSBFlZKRru545vjc7YzSarUUXv1+v4C9aLfbQtM0MZ1OxXg8ZivwfRAESrfbFY+YXq8nMaPRiL3kmEwm0iu1Wk15bg2HQ+WldcHU63XpdV1/p6rqDeyq2WxWEPkKWd8MBoM3fB/HMWf2AffXjUajgj1jKsBco4rXF1JOnT9w+NO2bQKAkDoZhkFJktBsNvt+LrmEH/p8jiB8TqZpkuu6vP/ImPl8LpTD4SAJ1+t1Nc9zStM03263D+wJKwzDW75HsJLv+/put6PNZnOEMeae/wHpJ8ZEUSQUACSh53lVACiMohz+QojMbk/gsOQ4jh6EIWd+YDLYcb/fPyW8u8skoe971d1uS+hVfo7+JEN/HZRc19HjOEIVyfEc9PhfhnFGknDlBr/SPdHSSwglMzHzEcr8IUuedV5NZouBsUzIMFfkeS5Zlk1oFaF3nxmDnoIwzeSUjb/mW8NcfjVM+4tlWWUY+28Ygpyy+ue36Gjd922tX9H0XhnDK0OTZUy9At3KKcOfpBMm2bMas23rRR1CJadNlu2lt2y7sFw5xdXKEWj+oxUXi4V8Kapah85GYjSeFPkFQVoCWYpOp1OEjC7i/gfuo+q0AqPFZgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a _load more_ action inside of a filter dropdown.",
            "title": "Example of a _load more_ action inside of a filter dropdown.",
            "src": "/static/86d8592506fe20e33e37dadeb4f5a2f5/fb070/load-more.png",
            "srcSet": ["/static/86d8592506fe20e33e37dadeb4f5a2f5/d6747/load-more.png 288w", "/static/86d8592506fe20e33e37dadeb4f5a2f5/09548/load-more.png 576w", "/static/86d8592506fe20e33e37dadeb4f5a2f5/fb070/load-more.png 1152w", "/static/86d8592506fe20e33e37dadeb4f5a2f5/c3e47/load-more.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of load more button in context.</Caption>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      